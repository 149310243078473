@-webkit-keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
@keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
.rai-dots .rai-circle {
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  -webkit-animation-name: dots-pulse;
          animation-name: dots-pulse;
  -webkit-animation-duration: inherit;
          animation-duration: inherit;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .rai-dots .rai-circle:last-child {
    margin-right: 0; }

@-webkit-keyframes levels-pulse {
  0% {
    height: 30%;
    opacity: 0.5; }
  20% {
    height: 100%;
    opacity: 1; }
  35% {
    height: 30%;
    opacity: 0.5; }
  55% {
    height: 100%;
    opacity: 1; }
  70% {
    height: 50%;
    opacity: 0.5; }
  80% {
    height: 100%;
    opacity: 1; }
  100% {
    height: 30%;
    opacity: 0.5; } }

@keyframes levels-pulse {
  0% {
    height: 30%;
    opacity: 0.5; }
  20% {
    height: 100%;
    opacity: 1; }
  35% {
    height: 30%;
    opacity: 0.5; }
  55% {
    height: 100%;
    opacity: 1; }
  70% {
    height: 50%;
    opacity: 0.5; }
  80% {
    height: 100%;
    opacity: 1; }
  100% {
    height: 30%;
    opacity: 0.5; } }
.rai-levels .rai-levels-container {
  text-align: left;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  -webkit-animation-duration: inherit;
          animation-duration: inherit; }
.rai-levels .rai-bar {
  bottom: 0;
  display: inline-block;
  vertical-align: bottom;
  width: 0.25em;
  background: #727981;
  margin-right: 0.125em;
  height: 50%;
  -webkit-animation-name: levels-pulse;
          animation-name: levels-pulse;
  -webkit-animation-duration: inherit;
          animation-duration: inherit;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .rai-levels .rai-bar:last-child {
    margin-right: 0; }

@-webkit-keyframes rai-sentry-pulse {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

@keyframes rai-sentry-pulse {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }
.rai-sentry {
  text-align: left;
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em; }
  .rai-sentry .rai-wave-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation-duration: inherit;
            animation-duration: inherit; }
  .rai-sentry .rai-wave {
    display: inline-block;
    border: 0.125em solid #727981;
    border-radius: 100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    -webkit-animation-name: rai-sentry-pulse;
            animation-name: rai-sentry-pulse;
    -webkit-animation-duration: inherit;
            animation-duration: inherit;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

@-webkit-keyframes rai-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rai-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
.rai-spinner {
  width: 1.5em;
  height: 1.5em;
  position: relative;
  margin: 0; }
  .rai-spinner-inner, .rai-spinner-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    margin-left: -0.0625em;
    margin-top: -0.0625em; }
  .rai-spinner-outer {
    border: 0.125em solid #727981;
    opacity: 0.2; }
  .rai-spinner-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-top: 0.125em solid #727981;
    border-right: 0.125em solid transparent !important;
    border-bottom: 0.125em solid transparent !important;
    border-left: 0.125em solid transparent !important;
    -webkit-animation-name: rai-spinner;
            animation-name: rai-spinner;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: inherit;
            animation-duration: inherit; }

@keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
.rai-squares .rai-square {
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  -webkit-animation-name: dots-pulse;
          animation-name: dots-pulse;
  -webkit-animation-duration: inherit;
          animation-duration: inherit;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .rai-squares .rai-square:last-child {
    margin-right: 0; }

@-webkit-keyframes digital-pulse {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@keyframes digital-pulse {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }
.rai-digital {
  height: 1em;
  text-align: center; }
  .rai-digital > div {
    display: inline-block;
    margin-right: 0.125em;
    background-color: #727981;
    width: 0.25em;
    height: 100%;
    -webkit-animation-name: digital-pulse;
            animation-name: digital-pulse;
    -webkit-animation-duration: inherit;
            animation-duration: inherit;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
    .rai-digital > div:last-child {
      margin-right: 0; }

@-webkit-keyframes bounce-pulse {
  0% {
    -webkit-transform: translateY(0.1875em);
            transform: translateY(0.1875em); }
  30% {
    -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em); }
  50% {
    -webkit-transform: translateY(3);
            transform: translateY(3); } }

@keyframes bounce-pulse {
  0% {
    -webkit-transform: translateY(0.1875em);
            transform: translateY(0.1875em); }
  30% {
    -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em); }
  50% {
    -webkit-transform: translateY(3);
            transform: translateY(3); } }
.rai-bounce > div {
  -webkit-transform: translateY(0.1875em);
          transform: translateY(0.1875em);
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  -webkit-animation-name: bounce-pulse;
          animation-name: bounce-pulse;
  -webkit-animation-duration: inherit;
          animation-duration: inherit;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .rai-bounce > div:last-child {
    margin-right: 0; }

@-webkit-keyframes windill-pulse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  99% {
    -webkit-transform: rotate(356deg);
            transform: rotate(356deg); } }

@keyframes windill-pulse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  99% {
    -webkit-transform: rotate(356deg);
            transform: rotate(356deg); } }
.rai-windill {
  width: 1em;
  height: 1em;
  position: relative; }
  .rai-windill > div {
    border-radius: 0.125em;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -0.0625em;
    width: 0.125em;
    height: 100%;
    -webkit-animation-name: windill-pulse;
            animation-name: windill-pulse;
    -webkit-animation-duration: inherit;
            animation-duration: inherit;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

